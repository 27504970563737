import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InfiniteLoop from "../components/infinite-loop"
import "../form.css"

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Life-tuning | Contact" />
      <StaticImage
        style={{
          width: "100%",
          boxShadow: "0 15px 16px 2px rgba(0, 0, 0, 0.1)",
        }}
        src="../images/this-way-life-tuning.jpeg"
        height={500}
        quality={100}
        objectFit={"cover"}
        objectPosition={"50% 40%"}
        formats={["auto", "webp", "avif"]}
        alt="dummy banner"
        placeholder="blurred"
      />
      <InfiniteLoop loop={4}>
        I am in the progress of becoming the best version of myself
      </InfiniteLoop>
      <section className="section-last">
        <form
          name="Life-tuning contact form"
          method="POST"
          netlify-honeypot="jam-field"
          data-netlify="true"
          action="/thank-you"
        >
          <input
            type="hidden"
            name="form-name"
            value="Life-tuning contact form"
          />
          <input type="hidden" name="jam-field" />
          <div id="form">
            <div>
              <div>
                <label name="firstName">Your First Name:</label>
                <input type="text" name="firstName" required />
              </div>
              <div>
                <label name="lastName">Your Last Name:</label>
                <input type="text" name="lastName" required />
              </div>
              <div>
                <label name="email">Your Email:</label>
                <input type="email" name="email" required />
              </div>
              <div>
                <label name="phone">Your Phone:</label>
                <input type="tel" name="phone" required />
              </div>
            </div>
            <div>
              <h2 className="subtitle--mb">Get in touch! </h2>
              <p>
                Thank you and congratulations for daring to take an important
                step towards your personal growth!
              </p>
              <p>
                By filling in this form, you’ll book a free discovery call. Feel
                free to add some information about your motivations and goals.
                (If you don’t have a clear vision yet, don’t worry! We’ll figure
                that out together.)
              </p>
              <p>
                I will be in touch within 48 hours to arrange a zoom call with
                you.
              </p>
              <p>I look forward to meeting you!</p>
            </div>
            <div>
              <label name="message">Message:</label>
              <textarea name="message" required />
            </div>
            <div>
              <button className="button-submit" type="submit">
                Send
              </button>
            </div>
          </div>
        </form>
      </section>
    </Layout>
  )
}

export default ContactPage
